$(document).on("change", "#diocese_select", function () {
  const dioceseId = $(this).val(); 
  const $prerequisiteAssessmentSelect = $("#prerequisite_assessment_select");
  const $certificationAssessmentSelect = $("#certification_assessment_select");
  const $settingsSelect = $("#settings_select");

  if (dioceseId) {
    $.ajax({
      url: "/admin/pcc_assessments/related_data",
      method: "GET",
      data: { diocese_id: dioceseId },
      success: function (data) {
        $prerequisiteAssessmentSelect.html('<option value="">Select an assessment</option>');
        $.each(data.prerequisite_assessments, function (index, assessment) {
          $prerequisiteAssessmentSelect.append(
            `<option value="${assessment.id}">${assessment.name}</option>`
          );
        });
        $prerequisiteAssessmentSelect.prop("disabled", false).removeClass("disabled");

        $certificationAssessmentSelect.html('<option value="">Select an assessment</option>');
        $.each(data.certification_assessments, function (index, assessment) {
          $certificationAssessmentSelect.append(
            `<option value="${assessment.id}">${assessment.name}</option>`
          );
        });
        $certificationAssessmentSelect.prop("disabled", false).removeClass("disabled");

        $settingsSelect.html('<option value="">Select a configuration</option>');
        $.each(data.settings, function (index, setting) {
          $settingsSelect.append(
            `<option value="${setting.id}">${setting.name}</option>`
          );
        });
        $settingsSelect.prop("disabled", false).removeClass("disabled");
      },
      error: function (xhr) {
        console.error("Error fetching data:", xhr.responseText);
      },
    });
  } else {
    $assessmentSelect.html('<option value="">Select an assessment</option>').prop("disabled", true).addClass("disabled");
    $settingsSelect.html('<option value="">Select a configuration</option>').prop("disabled", true).addClass("disabled");
  }
});
