// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.jQuery = $;
window.$ = $;
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
require('@nathanvda/cocoon');
require('bootstrap/dist/js/bootstrap');
require('jquery-validation/dist/jquery.validate');
require('packs/modals');
require('packs/timer');
require('views/pcc_assessment_settings');
require('views/pcc_assessments');
require('packs/popovers');
require('packs/charts');
require('packs/fix_conflicts');
require('select2/dist/js/select2.js');
require('flatpickr/dist/flatpickr');
import LocalTime from 'local-time';
LocalTime.start();
//= require jquery-ui

Rails.start();
Turbolinks.start();
ActiveStorage.start();
$(document).on('turbolinks:load', function () {
  $('.select2').select2({
    theme: 'bootstrap4',
  });
  $(".validate").validate();
  $('[data-toggle="tooltip"]').bstooltip();
  $('.validate_test').validate({
    invalidHandler: function (form, validator) {
      var errors = validator.numberOfInvalids();

      if (errors) {
        $('#error-message').show().text('Please select at least one option');
      } else {
        $('#error-message').hide();
      }
    },
    messages: {
      answer: {
        required: '',
      },
    },
  });
  // datepicker
  flatpickr('.datepicker', {
    altInput: true,
    dateFormat: 'm/d/Y',
  });
  flatpickr('.datetime_picker', {
    altInput: true,
    enableTime: true,
    allowInput: false,
    dateFormat: 'm/d/Y H:i',
  });

  flatpickr('.time_picker', {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    dateFormat: 'm/d/Y H:i',
  });

  // locale
  window.I18n = {
    translations: {},
    t: function(key) {
      return this.translations[key] || key;
    }
  };

  function loadTranslations() {
    $.ajax({
      url: '/translations',
      method: 'GET',
      success: function(data) {
        window.I18n.translations = data;
      }
    });
  }
  
  $(document).ready(function() {
    loadTranslations();
  });
  

  // Data Tables
  var dataTables = $('.data-table');

  var customDataTable = function customDataTable(elem) {
    elem.find('.pagination').addClass('pagination-sm');
  };

  dataTables.length &&
    dataTables.each(function (index, value) {
      var $this = $(value);
      var options = $.extend(
        {
          responsive: true,
          paging: false,
          searching: false,
          dom:
            "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" +
            "<'table-responsive'tr>" +
            "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>",
        },
        $this.data('options')
      );
      $this.DataTable(options);
      var $wrpper = $this.closest('.dataTables_wrapper');
      customDataTable($wrpper);
      $this.on('draw.dt', function () {
        return customDataTable($wrpper);
      });
    });
  function searchUers(value) {
    var htmlList = '';
    // getting json data from file for search results
    $.get('/admin/full_search?q=' + value, function (data) {
      for (var i = 0; i < data.length; i++) {
        htmlList += data[i].list_html;
      }
      $('ul.header-search-list').html(htmlList); // Appending list to <ul>
    });
  }
  var timer = null;
  // top search area start
  $('.header-search-input').on('keyup', function (e) {
    if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
      if (e.keyCode == 27) {
        $('.search-input').removeClass('d-block');
        $('.app-content').removeClass('show-overlay');
      }
      var value = $(this).val().toLowerCase();
      var liList = $('ul.header-search-list li'); // get all the list items of the search
      liList.remove();
      if (value != '') {
        $('.header-search-list').addClass('d-block');
        $('.app-content').addClass('show-overlay');
        clearTimeout(timer);
        timer = setTimeout(searchUers(value), 1000);
      } else {
        // If filter box is empty
        if ($('.header-search-list').hasClass('d-block')) {
          $('.header-search-list').removeClass('d-block');
        }
        $('.app-content').removeClass('show-overlay');
      }
    }
    $('input[type=search]').on('search', function () {
      $('.header-search-list').removeClass('d-block');
      $('.app-content').removeClass('show-overlay');
    });
  });
  // top search area end
  // open search card and close it
  $('.open-search').on('click', function () {
    $('.search-card').toggleClass('d-none');
    return false;
  });
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // save the latest tab; use cookies if you like 'em better:
    localStorage.setItem('lastTab', $(this).attr('href'));
  });

  // go to the latest tab, if it exists:
  var lastTab = localStorage.getItem('lastTab');
  if (lastTab) {
    $('[href="' + lastTab + '"]').tab('show');
  }

  // dynamic dropdowns
  var $diocese = $('.diocese-select').select2({ theme: 'bootstrap4' });
  var $testing_center = $('.testing-center-select').select2({
    theme: 'bootstrap4',
  });
  $diocese.on('select2:select', function (e) {
    $.ajax({
      method: 'GET',
      data: { diocese: $(this).val() },
      url: '/admin/centers_list',
    }).done(function (resp) {
      $('.testing-center-select').empty().trigger('change');
      // create the option and append to Select2
      $.each(resp, function (i, value) {
        console.log(value);
        var option = new Option(value.text, value.id, true, true);
        $testing_center.append(option);
      });
      $('.testing-center-select').trigger('change');
      $('.testing-center-select').val(null).trigger('change');
    });
  });
  $('.testing-center-select').on('change', function () {
    $.ajax({
      method: 'GET',
      data: { testing_center: $(this).val(), current_year: $(this).data("year") },
      url: '/admin/testing_center_assessment',
    }).done(function (resp) {
      $('.testing-center-assessment').empty().trigger('change');
      // create the option and append to Select2
      $.each(resp, function (i, value) {
        var option = new Option(value.text, value.id, true, true);
        $('.testing-center-assessment').append(option);
      });
      $('.testing-center-assessment').trigger('change');
      $('.testing-center-assessment').val(null).trigger('change');
    });
  });
  handleNavbarVerticalCollapsed();
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
});


function handleNavbarVerticalCollapsed() {
  var Selector = {
    HTML: 'html',
    NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
    NAVBAR_VERTOCAL_COLLAPSE: '.navbar-vertical .navbar-collapse',
    ECHART_RESPONSIVE: '[data-echart-responsive]',
  };
  var Events = {
    CLICK: 'click',
    MOUSE_OVER: 'mouseover',
    MOUSE_LEAVE: 'mouseleave',
    NAVBAR_VERTICAL_TOGGLE: 'navbar.vertical.toggle',
  };
  var ClassNames = {
    NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
    NAVBAR_VERTICAL_COLLAPSED_HOVER: 'navbar-vertical-collapsed-hover',
  };
  var navbarVerticalToggle = document.querySelector(
    Selector.NAVBAR_VERTICAL_TOGGLE
  );
  var html = document.querySelector(Selector.HTML);
  var navbarVerticalCollapse = document.querySelector(
    Selector.NAVBAR_VERTICAL_COLLAPSE
  );

  if (navbarVerticalToggle) {
    navbarVerticalToggle.addEventListener(Events.CLICK, function (e) {
      html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED); // Set collapse state on localStorage

      var isNavbarVerticalCollapsed = utils.getItemFromStore(
        'isNavbarVerticalCollapsed'
      );
      utils.setItemToStore(
        'isNavbarVerticalCollapsed',
        !isNavbarVerticalCollapsed
      );
      var event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
      e.currentTarget.dispatchEvent(event);
    });
  }

  if (navbarVerticalCollapse) {
    navbarVerticalCollapse.addEventListener(Events.MOUSE_OVER, function () {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED)) {
        html.classList.add(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
      }
    });
    navbarVerticalCollapse.addEventListener(Events.MOUSE_LEAVE, function () {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER)) {
        html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
      }
    });
  }
}
