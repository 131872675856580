$(document).on("change", "#pcc_assessment_setting_default_setting", function () {
  const $select = $("#pcc_assessment_setting_diocese_id");
  
  let $labelContainer = $select.next(".text-muted");
  if ($labelContainer.length === 0) {
    $labelContainer = $("<p class='text-muted mt-1'></p>");
    $select.after($labelContainer);
  }

  if ($(this).is(":checked")) {
    const arkOption = $select.find("option").filter(function () {
      return $(this).text().trim() === "ARK Admin Diocese";
    }).val();
  
    if (arkOption) {
      $select.val(arkOption); 
      $labelContainer.text('Selected: ARK Admin Diocese');
      $("#hidden_diocese_id").val(arkOption);
      $select.prop("disabled", true);
    } else {
      console.error(errorText);
    }
  } else {
    $select.prop("disabled", false).val("");
    $("#hidden_diocese_id").val("");
    $labelContainer.text("");
  }
});